import { Accordion, AccordionGroup, AccordionItem, AccordionLabel } from '../ui/Accordion';
import { Container, ResultsWrapper, H5, Navigation, RiskRatingCard, RiskLevelsCard, Button } from './styles';
import { useRiskRating } from './hooks';
import riskRatings from './riskRatings';
import { H2, H3, P } from '../ui/Typography';
import LoadingState from './LoadingState';
import { AccordionGroupChangeEventDetail, IonAccordionGroupCustomEvent } from '@ionic/core';
import { logAnalyticsEvent } from 'services/analytics';

export interface Risk {
    level: string;
    description: string;
}

const allRatings = Object.keys(riskRatings).map(key => riskRatings[key]);

function Results() {
    const { rating, description, retakeQuestionnaire } = useRiskRating();

    const accordionGroupChange = (e: IonAccordionGroupCustomEvent<AccordionGroupChangeEventDetail<any>>) => {
        if (e.detail.value) {
            logAnalyticsEvent({ action: `Results page`, label: `${e.detail.value} risk level accordion` });
        }
    };

    return (
        <Container>
            {!rating && <LoadingState />}

            {rating && (
                <>
                    <H2>Based on your answers</H2>

                    <ResultsWrapper>
                        <RiskRatingCard>
                            <H3>Your attitude to risk is {rating.toLowerCase()}</H3>

                            <P>{description}</P>

                            <Navigation>
                                <Button onClick={retakeQuestionnaire}>Re-take the test</Button>
                            </Navigation>
                        </RiskRatingCard>

                        <RiskLevelsCard>
                            <H5>The risk level categories are:</H5>

                            <AccordionGroup onIonChange={accordionGroupChange}>
                                {allRatings.map(({ rating, description }) => (
                                    <Accordion key={rating} value={rating}>
                                        <AccordionItem slot="header" color="light">
                                            <AccordionLabel>{rating}</AccordionLabel>
                                        </AccordionItem>
                                        <div className="ion-padding" slot="content">
                                            {description}
                                        </div>
                                    </Accordion>
                                ))}
                            </AccordionGroup>
                        </RiskLevelsCard>
                    </ResultsWrapper>
                </>
            )}
        </Container>
    );
}

export default Results;
