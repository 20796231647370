import styled from 'styled-components';
import { lighten } from 'polished';
// import { IonButton as IonicButton } from '@ionic/react';

type CustomButton = {
    secondary?: boolean;
};

export const StyledButton = styled.button<CustomButton>`
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
    height: 44px;
    margin: 0;
    background-color: ${({ theme, secondary }) => (!!secondary ? 'transparent' : theme.colors.primaryAction)};
    border: ${({ theme, secondary }) => (!!secondary ? `1px solid ${theme.colors.primaryAction}` : 'none')};
    color: ${({ theme, secondary }) => (!!secondary ? theme.colors.primaryAction : theme.colors.white)};
    border-radius: 4px;
    padding: 5px 16px;

    :hover,
    :active,
    :focus {
        background-color: ${({ theme, secondary }) =>
            !!secondary ? 'transparent' : lighten(0.05, theme.colors.primaryAction)};
        border: ${({ theme, secondary }) =>
            !!secondary ? `1px solid ${lighten(0.05, theme.colors.primaryAction)}` : 'none'};
    }

    &[disabled] {
        opacity: 0.5;
    }
`;

// removed the IonicButton compomnent as it fails AA accessibility tests!

// export const IonButton = styled(IonicButton)`
//     --background: #147cb3;
//     --background-hover: #9ce0be;
//     --background-activated: #88f4be;
//     --background-focused: #88f4be;
//     --color: #fff;
//     --border-radius: 4px;
//     --border-width: 0;
//     --ripple-color: transparent;
//     --padding: 5px 16px;
//     --box-shadow: none;

//     font-family: Arial, Helvetica, sans-serif;
//     font-size: 16px;
//     line-height: 24px;
//     text-transform: none;
//     height: 44px;
//     margin: 0;

//     background-color: #147cb3;

//     :hover {
//         background-color: #9ce0be;
//     }
// `;
