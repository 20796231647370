import styled, { keyframes } from 'styled-components';
import { breakpointUp } from '../../helpers/breakpoints';
import uiButton from '../ui/Button';

const pulseFade = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
`;

export const Fieldset = styled.fieldset`
    border: none;
    padding: 0;
    margin: 0 0 30px 0;
`;

export const Legend = styled.legend`
    font-size: 20px;
    line-height: 28px;
    font-weight: bold;
    padding: 0;
    margin-bottom: 30px;
    color: ${({ theme }) => theme.colors.primaryText};

    ${breakpointUp.md`
        font-size: 22px;
        line-height: 30px;
    `}
`;

export const Answers = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.cardBorder};
    border-radius: 8px;
    padding: 0 12px;
`;

export const Answer = styled.div`
    position: relative;
    margin: 15px 0;
`;

export const Input = styled.input`
    position: absolute;
    -webkit-appearance: none;
    appearance: none;
    margin: 2px 0 0 0;
    font: inherit;
    color: currentColor;
    width: 20px;
    height: 20px;
    border: 0.15em solid ${({ theme }) => theme.colors.bodyText};
    border-radius: 50%;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;

    &::before {
        content: '';
        width: 11px;
        height: 11px;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em ${({ theme }) => theme.colors.primaryAction};
    }

    &:checked {
        border: 0.15em solid ${({ theme }) => theme.colors.primaryAction};
        &::before {
            transform: scale(1);
        }
    }
`;

export const Label = styled.label`
    display: block;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 24px;
    padding-left: 30px;
    color: ${({ theme }) => theme.colors.primaryText};
`;

export const Navigation = styled.div`
    ${breakpointUp.md`
        display: flex;
        justify-content: flex-end;
    `}
`;

export const QuestionSkeleton = styled.div`
    width: 100%;
    height: 20px;
    max-width: 650px;
    background-color: rgba(217, 219, 221, 0.5);
    border-radius: 5px;
    margin-bottom: 30px;
    animation-name: ${pulseFade};
    animation-duration: 1.75s;
    animation-timing-function: ease-in-out;
    animation-delay: 1s;
    animation-iteration-count: infinite;
`;

export const AnswersSkeleton = styled.div`
    width: 100%;
    height: 210px;
    background-color: rgba(217, 219, 221, 0.5);
    border-radius: 5px;
    margin-bottom: 30px;
    animation-name: ${pulseFade};
    animation-duration: 1.75s;
    animation-timing-function: ease-in-out;
    animation-delay: 1s;
    animation-iteration-count: infinite;
`;

export const ButtonSkeleton = styled.div`
    width: 100%;
    max-width: 145px;
    height: 44px;
    background-color: rgba(217, 219, 221, 0.5);
    border-radius: 5px;
    margin-bottom: 30px;
    animation-name: ${pulseFade};
    animation-duration: 1.75s;
    animation-timing-function: ease-in-out;
    animation-delay: 1s;
    animation-iteration-count: infinite;
`;

export const Button = styled(uiButton)`
    width: 100%;

    ${breakpointUp.md`
        width: initial;
    `}
`;
