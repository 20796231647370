import styled from 'styled-components';
import { breakpointUp } from '../../helpers/breakpoints';
import uiAlert from '../ui/Alert';
import uiButton from '../ui/Button';

export const Container = styled.div`
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
    padding: 0 15px;
`;

export const Alert = styled(uiAlert)`
    margin: 20px 0 30px 0;
`;

export const Navigation = styled.div`
    ${breakpointUp.md`
        display: flex;
        justify-content: flex-end;
    `}
`;

export const Button = styled(uiButton)`
    width: 100%;

    ${breakpointUp.md`
        width: initial;
    `}
`;
