import styled from 'styled-components';

export const H1 = styled.h1`
    font-size: 42px;
    line-height: 50px;
    margin-bottom: 20px;
`;

export const Container = styled.div`
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
    padding: 50px 15px;
`;
