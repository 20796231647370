import { H2, P } from '../ui/Typography';
import { useStartQuestionnaire } from './hooks';
import { Container, Alert, Navigation, Button } from './styles';

function QuestionnaireStart() {
    const { startQuestionnaire } = useStartQuestionnaire();

    return (
        <Container>
            <H2>Take our questionnaire</H2>

            <P>
                The attitude to risk questionnaire contains 13 multiple choice questions which you need to answer to
                discover your category. It should take less than 5 minutes.
            </P>

            <Alert title="We don't store your data">
                <P>
                    You don't need to tell us anything about yourself, just how you would react to or feel in certain
                    scenarios. We won't store any of this information in a way that can be linked back to you.
                </P>
            </Alert>

            <Navigation>
                <Button onClick={startQuestionnaire}>I'm ready to start</Button>
            </Navigation>
        </Container>
    );
}

export default QuestionnaireStart;
