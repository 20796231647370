import cookie from 'cookiejs';
import Cookies from 'js-cookie';
import TagManager from 'react-gtm-module';

const { REACT_APP_GTM_API_KEY, REACT_APP_ENABLE_ANALYTICS_DEBUGGING } = process.env;

interface AnalyticsEvent {
    action: string;
    label: string;
}

const SL_COOKIE_NAME = 'sl#cookiepreferences';
const SL_GTM_EVENT = 'riskProfilerTool';
const SL_GTM_EVENT_CATEGORY = 'Attitude to risk questionnaire';

declare const window: any;

const disableDefaultTracker = (): any => {
    if (window.ga) window.ga('remove');
    Cookies.remove('_ga', { path: '/', domain: document.domain });
    Cookies.remove('_gid', { path: '/', domain: document.domain });
    Cookies.remove('_gat', { path: '/', domain: document.domain });
};

export const analyticsCookiesEnabled = (): boolean => {
    try {
        const cookieValue = cookie.get(SL_COOKIE_NAME);
        if (!cookieValue) return false;
        const { cookiecategoryids } = JSON.parse(cookieValue as string);
        const categoryids = cookiecategoryids.split('|');
        // return true if cookiecategoryid '2' exists in the list
        const isEnabled = categoryids.indexOf('2') !== -1;
        if (!isEnabled) {
            disableDefaultTracker();
        }
        return isEnabled;
    } catch {
        return false;
    }
};

export const logAnalyticsEvent = ({ action: eventAction, label: eventLabel }: AnalyticsEvent) => {
    if (analyticsCookiesEnabled() && REACT_APP_GTM_API_KEY) {
        try {
            const dataLayer = {
                event: SL_GTM_EVENT,
                eventCategory: SL_GTM_EVENT_CATEGORY,
                eventAction,
                eventLabel,
            };
            TagManager.dataLayer({
                dataLayer,
            });
            if (REACT_APP_ENABLE_ANALYTICS_DEBUGGING === 'true') {
                console.log('Google Tag Manager dataLayer event', dataLayer);
            }
        } catch (e) {
            console.log('Google Tag Manager dataLayer error', e);
        }
    }
};
