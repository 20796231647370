const theme = {
    colors: {
        primaryText: '#051a3f',
        primaryAction: '#147cb3',
        paleBlue: '#eff6fa',
        bodyText: '#656a76',
        progressComplete: '#008616',
        progressCurrent: '#4396c2',
        cardBorder: '#d9dbdd',
        loadingSkeleton: 'rgba(217, 219, 221, 0.5)',
        black: '#000000',
        white: '#ffffff',
    },
};

export default theme;
