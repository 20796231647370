import styled from 'styled-components';
import {
    IonAccordion as IonicAccordion,
    IonAccordionGroup as IonicAccordionGroup,
    IonItem as IonicItem,
    IonLabel as IonicLabel,
} from '@ionic/react';

export const Accordion = styled(IonicAccordion)`
    div[slot='content'] {
        font-size: 16px;
        line-height: 24px;
    }
`;

export const AccordionGroup = styled(IonicAccordionGroup)``;

export const AccordionItem = styled(IonicItem)`
    --ripple-color: transparent;
    --ion-color-base: transparent !important;
    --background-hover-opacity: 0 !important;
    --border-bottom-width: 1px !important;
    --ion-color-contrast: ${({ theme }) => theme.colors.primaryText} !important;
`;

export const AccordionLabel = styled(IonicLabel)``;
