import styled from 'styled-components';
import { breakpointUp } from '../../../helpers/breakpoints';

export const H1 = styled.h1`
    font-family: 'Ubuntu', sans-serif;
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 30px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primaryText};

    ${breakpointUp.md`
        font-size: 52px;
        line-height: 62px;
    `}
`;

export const H2 = styled.h1`
    font-family: 'Ubuntu', sans-serif;
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 24px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primaryText};

    ${breakpointUp.md`
        font-size: 36px;
        line-height: 44px;
    `}
`;

export const H3 = styled.h2`
    font-family: 'Ubuntu', sans-serif;
    font-size: 20px;
    line-height: 28px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primaryText};

    ${breakpointUp.md`
        font-size: 24px;
        line-height: 32px;
    `}
`;

export const H4 = styled.h3`
    font-family: 'Ubuntu', sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primaryText};

    ${breakpointUp.md`
        font-size: 20px;
        line-height: 28px;
    `}
`;

export const H5 = styled.h3`
    font-family: 'Ubuntu', sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primaryText};

    ${breakpointUp.md`
        font-size: 18px;
        line-height: 26px;
    `}
`;

export const P = styled.p`
    font-size: 16px;
    line-height: 24px;
`;
