import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logAnalyticsEvent } from 'services/analytics';
import graphQLMutation from '../../services/graphql/mutation';
import { RESET_FORM, SET_RISK_RATING, useAppContext } from '../../state';
import riskRatings from './riskRatings';

interface RiskRating {
    hasQuestions?: boolean;
    rating?: string;
    description?: string;
    retakeQuestionnaire: Function;
}

export const useRiskRating = (): RiskRating => {
    const { state, dispatch } = useAppContext();
    const [responsesSubmitted, setResponsesSubmitted] = useState(false);
    let rating, description;

    const navigate = useNavigate();

    useEffect(() => {
        if (!state.questions.length) {
            navigate('/');
        }
        async function submitResponses() {
            try {
                const { rating } = await graphQLMutation('calculateRisk', { responses: state.answers });
                logAnalyticsEvent({ action: `Results page`, label: `Attitude to risk - ${rating}` });
                dispatch({ type: SET_RISK_RATING, riskRating: rating });
            } catch {
                navigate('/error');
            }
        }
        if (
            !responsesSubmitted &&
            state.answers.length > 0 &&
            state.answers.length === state.questions.length &&
            !state.riskRating
        ) {
            logAnalyticsEvent({ action: `Results page`, label: `Display` });
            setResponsesSubmitted(true);
            submitResponses();
        }
    }, [state, dispatch, responsesSubmitted, navigate]);

    const retakeQuestionnaire = useCallback(() => {
        logAnalyticsEvent({ action: `Results page`, label: `Re-take the test button` });
        dispatch({ type: RESET_FORM });
        navigate('/questionnaire');
    }, [dispatch, navigate]);

    if (state.riskRating) {
        const results = riskRatings[state.riskRating.toString()];
        rating = results.rating;
        description = results.description;
    }

    const hasQuestions = !!state.questions;

    return {
        hasQuestions,
        rating,
        description,
        retakeQuestionnaire,
    };
};
